<template>
  <div class="all-blog">
    <div class="bg-img">
      <BgBlogsMobie class="bgMobile" v-if="$root.$data.isMobile" />
      <BgBlogs class="bg" :perPage="perPage" v-else />
    </div>
    <div class="bloglists">
      <b-container fluid class="contianer">
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="4">
          <!--  -->
          <b-col v-for="(blog, index) in blogsPerPage" :key="blog.id">
            <b-card
              :title="blog.title"
              :img-src="getImgUrl(blog.imagepath)"
              :class="{ selected: isSelected(index) }"
              :img-alt="blog.title"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 3%"
              class="mb-2"
              @mouseover="hoverCard(index)"
              @mouseout="hoverCard(-1)"
              @click="openBlog(blog)"
            >
              <b-card-text>
                <small class="text-monospace font-weight-normal description"
                  >{{ blog.description }}..</small
                >
              </b-card-text>
              <div>
                <span
                  class="category"
                  @click.stop.prevent="openCategory(blog.category)"
                >
                  <a
                    ><small>{{ blog.category }}</small></a
                  >
                </span>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <BigPagin
        :activePage="activePage"
        v-bind:blogLength="blogs.length"
        v-bind:perPage="perPage"
        @pageChange="changePage"
      ></BigPagin>
    </div>
    <Register class="register" />
  </div>
</template>

<script>
import BgBlogs from "./helpers/BgBlogs.vue";
import BgBlogsMobie from "./mobile/BgBlogsMobile.vue";
import BigPagin from "./helpers/BigPagin.vue";
import Register from "./helpers/Register.vue";
import { ToBlog } from "./helpers/utils.js";

export default {
  components: {
    BgBlogs,
    BgBlogsMobie,
    BigPagin,
    Register,
  },
  name: "blogs",
  props: {
    blogId: String,
    blogsApp: [],
  },
  data() {
    return {
      perPage: 16,
      currentPage: 1,
      blogs: [],
      selectedCard: -1,
      slide: 0,
      activePage: 1,
      sliding: null,
    };
  },
  computed: {
    rows() {
      return this.blogs.length;
    },
    blogsPerPage() {
      return this.blogs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    formattedBlogs() {
      return this.blogs.reduce((b, n, i) => {
        if (i % 4 === 0) b.push([]);
        b[b.length - 1].push(n);
        return b;
      }, []);
    },
  },
  async created() {
    await this.fetchBlogs();
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    async fetchBlogs() {
      this.blogs = await this.$root.$data.fetchedBlogs;
      await this.blogs.forEach((blog) => {
        blog.description = blog.description.slice(0, 60);
      });
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.activePage = currentPage;
    },
    openBlog(blog) {
      ToBlog(blog.id, blog.title);
    },
    openCategory(category) {
      localStorage.setItem("category", category);
      this.$router
        .push({
          name: "Category",
        })
        .catch(() => {});
    },
    getImgUrl(imageName) {
      // console.log(imageName);
      var images = require.context("../assets/figure/", false, /\.png$/);
      return images("./" + imageName + ".png");
    },
    hoverCard(selectedIndex) {
      this.selectedCard = selectedIndex;
    },
    isSelected(blogIndex) {
      return this.selectedCard === blogIndex;
    },
  },
};
</script>

<style lang="css" scoped>
.all-blog {
  background-color: rgb(31, 72, 126, 0.05);
  padding-bottom: 50px;
}
.bg-img {
  background-image: url("../assets/figure/backend_light_lg.png");
  background-size: cover;
  min-width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  top: 0;
  right: 0;
  border: none;
}

.bg {
  margin: 0 auto;
  max-width: 80%;
}

.bgMobile {
  margin: 0 auto;
  max-width: 95%;
}

.bloglists {
  max-width: 95%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.contianer {
  padding-bottom: 200px;
}

.card {
  text-align: left;
  margin: 1%;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #376996;
  transition: height 0.5s, box-shadow 0.5s;
}

.card:hover {
  box-shadow: 2px 6px 10px 0px #376996;
}
.card img {
  transition: all 0.5s ease-in-out;
  border-radius: 5%;
}
.card:hover img {
  transform: scale(1.02);
}
.card:hover .category::before {
  width: 9em;
}

@media screen and (max-width: 600px) {
  .card {
    transition: none;
  }
  .card:hover {
    box-shadow: 0px 1px 2px 0px #376996;
  }
  .card img {
    transition: none;
    border-radius: 5%;
  }
  .card:hover img {
    transform: none;
  }
  .card:hover .category::before {
    width: auto;
  }
  .register {
    max-width: 90%;
    margin: auto;
  }
}
</style>
