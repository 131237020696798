<template>
  <div id="bgCards">
    <div v-for="(blog, index) in blogsPerPage" :key="blog.id">
      <b-card
        id="cardEl"
        no-body
        style="max-width: 100%; max-height: 500px"
        :class="{ selected: isSelected(index) }"
        @mouseover="hoverCard(index)"
        @mouseout="hoverCard(-1)"
        v-dragged="onDragged"
      >
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              :src="getImgUrl(blog.imagepath)"
              :img-alt="blog.title"
              class="rounded-2"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body :title="blog.title">
              <b-card-text> {{ blog.description }}.. </b-card-text>
              <div>
                <b-button @click="openBlog(blog)" class="submit" size="md"
                  ><small>Learn more</small></b-button
                >

                <span
                  class="bgCategory"
                  @click.stop.prevent="openCategory(blog.category)"
                >
                  <a
                    ><small>{{ blog.category }}</small></a
                  >
                </span>
              </div>
            </b-card-body>
          </b-col>
          <!-- <div 
          class="prevNext">
          <b-button @click="prevPage()" class="prev"  size="sm"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button @click="nextPage()" class="next"  size="sm"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg> 
          </b-button>
        </div> -->
        </b-row>
      </b-card>
      <Pagin
        v-bind:activePage="activePage"
        v-bind:perPage="perPage"
        v-bind:blogLength="getLength()"
        @pageChange="changePage"
      />
    </div>
  </div>
</template>

<script>
import Pagin from "../helpers/Pagin";
import moment from "moment";
import { ToBlog } from "../helpers/utils.js";

export default {
  components: {
    Pagin,
  },
  name: "BgBlogsMobile",
  props: {
    blogId: String,
    blogLength: Number,
  },
  data() {
    return {
      activePage: 1,
      perPage: 1,
      currentPage: 1,
      blogs: [],
      selectedCard: -1,
      slider: null,
      isMoving: false,
    };
  },
  watch: {
    isMoving() {
      document.querySelector("body").style.overflow = this.isMoving
        ? "hidden"
        : null;
    },
  },
  computed: {
    rows() {
      return this.blogs.length;
    },
    blogsPerPage() {
      return this.blogs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    formattedBlogs() {
      return this.blogs.reduce((b, n, i) => {
        if (i % 4 === 0) b.push([]);
        b[b.length - 1].push(n);
        return b;
      }, []);
    },
  },
  created() {
    this.fetchBlogs();
  },
  mounted() {
    if (this.slider) {
      clearInterval(this.slider);
    } else {
      this.slider = setInterval(() => {
        if (this.currentPage < this.blogs.length) {
          this.currentPage++;
          this.activePage++;
        } else {
          this.currentPage = 1;
          this.activePage = 1;
        }
      }, 7000);
    }
  },
  methods: {
    prev(e) {
      e.preventDefault();
    },
    totalPages() {
      return Math.ceil(this.blogs.length / this.perPage);
    },
    dateThreshold(d, nrOfMonth) {
      const month = d.getMonth();
      d.setMonth(d.getMonth() - nrOfMonth);
      while (month === d.getMonth()) {
        d.setDate(d.getDate() - nrOfMonth);
      }
      return d;
    },
    fetchBlogs() {
      this.blogs = this.$root.$data.fetchedBlogs;
      this.blogs.forEach((blog) => {
        if (blog.title.length > 30) {
          blog.title = blog.title.slice(0, 30);
          blog.title += "..";
        }
        blog.description = blog.description.slice(0, 40);
      });
      var d = this.dateThreshold(new Date(), 6);
      var dateThresh = moment(new Date(d.toDateString())).format("YYYY-MM-DD");
      this.blogs.filter((blog) => {
        return dateThresh > moment(new Date(blog.pubdate)).format("YYYY-MM-DD");
      });
      if (this.blogs.length > 6) {
        this.blogs = this.blogs.slice(0, 5);
      }
    },
    changePage(currentPage) {
      clearInterval(this.slider);
      this.currentPage = currentPage;
      this.activePage = currentPage;
    },
    /* prevPage() {
      clearInterval(this.slider);
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage-1;
        this.activePage = this.currentPage;
      } else {
        this.currentPage = this.totalPages();
        this.activePage = this.totalPages();
      }
    },
    nextPage() {
      clearInterval(this.slider);
      if (this.currentPage < this.totalPages()) {
        this.currentPage = this.currentPage+1;
        this.activePage = this.currentPage;
      } else {
        this.currentPage = 1;
        this.activePage = 1;
      }
    }, */
    getLength() {
      return this.blogs.length;
    },
    openBlog(blog) {
      ToBlog(blog.id, blog.title);
    },
    openCategory(category) {
      localStorage.setItem("category", category);
      this.$router
        .push({
          name: "Category",
        })
        .catch(() => {});
    },
    getImgUrl(imageName) {
      var images = require.context("../../assets/figure/", false, /\.png$/);
      return images("./" + imageName + ".png");
    },
    hoverCard(selectedIndex) {
      this.selectedCard = selectedIndex;
    },
    isSelected(blogIndex) {
      return this.selectedCard === blogIndex;
    },
    onDragged({ el, deltaX, /* offsetX, clientX, */ deltaY, first, last }) {
      if (first) {
        this.isMoving = true;
        this.isDragging = true;
        return;
      }
      var l = +window.getComputedStyle(el)["left"].slice(0, -2) || 0;
      if (Math.abs(deltaY) < 3) {
        el.style.left = l + deltaX + "px";
      }
      if (last) {
        this.isMoving = false;
        if (l > 35) {
          if (this.currentPage == this.totalPages()) {
            this.changePage(1);
            this.isDragging = false;
            return;
          } else {
            this.changePage(this.currentPage + 1);
            this.isDragging = false;
            return;
          }
        }
        if (l < -35) {
          // todo: left does not work
          if (this.currentPage == 1 || this.currentPage < 1) {
            this.changePage(this.totalPages());
            this.isDragging = false;
            return;
          }
          if (this.currentPage > 1) {
            this.changePage(this.currentPage - 1);
            this.isDragging = false;
            return;
          }
        }
        el.style.left = "0px";
        this.isDragging = false;
        return;
      }
    },
  },
};
</script>

<style scoped>
#bgCards {
  margin: auto;
  padding-top: 100px;
  padding-bottom: 50px;
  overflow: hidden;
  touch-action: pan-down;
  touch-action: pan-up;
}

.card {
  margin: auto;
  vertical-align: middle;
  text-align: left;
  margin: 2%;
  /* overflow-x: hidden; */
  touch-action: pan-y;
  box-shadow: 0px 1px 2px 0px #376996;
}

/* .card:hover {
  box-shadow: 2px 6px 10px 0px #376996;
} */

.card-title {
  font-weight: bold;
  font-size: large;
}

.submit {
  display: inline-block;
  /*  padding: 12px 24px; */
  /* padding-bottom: 5px; */
  background-color: #376996;
  border: 1px solid #376996;
  border-radius: 4px;
  transition: all 0.8s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 0.95em;
  color: #ffff;
  z-index: 1;
}

.bgCategory {
  display: block;
  height: 25px;
  line-height: 25px;
  width: 40px;
  font-size: 0.7rem;
  color: #ffffff;
  text-align: center;
  background-color: #a10702;
  border-radius: 1%;
  border: 0.3px solid #a10702;
  box-shadow: 0.5px 0.5px 1px #f44708, -0.5px -0.5px 1px #ffffff;
  margin: 0.75%;
  float: right;
}
.dot:hover {
  background-color: #a10702;
}
/* 
.prevNext {
  vertical-align: middle;
  margin: 0;
  left: 0.04%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0.05%;
  opacity: 0.7;
  width: 100%;
  z-index: 10;
}
.prevNext .prev{
  background-color: #ffff;
  color: #1f487e;
  text-align: center;
  border-radius: 50%;
  border: 0.5px solid #6290c8;
  width: 33px;
  height: 33px;
  box-shadow: 0px 1px 2px 0px #376996;
}

.prevNext .next{
  background-color: #ffff;
  color: #1f487e;
  text-align: center;
  border-radius: 50%;
  border: 0.5px solid #6290c8;
  width: 33px;
  height: 33px;
  float:right;
  box-shadow: 0px 1px 2px 0px #376996;
} */
</style>
